.profile-nav {
    margin-left: auto;
    margin-right: -10px;
    position: relative;
    max-width: 100%;
    text-align: center;
    z-index: 200;
    cursor: pointer;
}

.profile-nav img {
    border: 1px solid var(--boundry-color);
}

.dropdown:checked + label,
.dropdown:not(:checked) + label {
  position: relative;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 2;
  height: 20px;
  transition: all 200ms linear;
  border-radius: 4px;
  width: 20px;
  letter-spacing: 1px;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
   align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  text-align: center;
  border: none;
  cursor: pointer;
  color: var(--boundry-color);
  box-shadow: 0 12px 35px 0 rgba(255,235,167,.15);
}

.dropdown:checked + label:before,
.dropdown:not(:checked) + label:before{
  position: fixed;
  top: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 100%;
  z-index: -1;
  cursor: auto;
  pointer-events: none;
}

.dropdown:checked + label:before{
  pointer-events: auto;
}

.dropdown:not(:checked) + label .uil {
  font-size: 24px;
  margin-left: 10px;
  transition: transform 200ms linear;
}

.dropdown:checked + label .uil {
  transform: rotate(180deg);
  font-size: 24px;
  margin-left: 10px;
  transition: transform 200ms linear;
}

.section-dropdown {
  position: absolute;
  padding: 5px;
  background-color: var(--primary-color);
  top: 80px;
  left: 0;
  width: 100%;
  border-radius: 4px;
  display: block;
  box-shadow: 0 14px 35px 0 rgba(9,9,12,0.4);
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  transform: translateY(20px);
  transition: all 200ms linear;
  background-image: var(--background-image), linear-gradient(43deg, var(--secondary-color) 0%, var(--primary-color) 80%, var(--support-color) 200%);
}

.dropdown:checked ~ .section-dropdown{
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
  border: 1px solid var(--boundry-color);
  z-index: 1000000000000!important;
}

.section-dropdown:before {
  position: absolute;
  top: -20px;
  left: 0;
  width: 100%;
  height: 20px;
  content: '';
  display: block;
  z-index: 1;
}

.section-dropdown:after {
  position: absolute;
  top: -7px;
  left: 150px;
  width: 0; 
  height: 0; 
  border-left: 8px solid transparent;
  border-right: 8px solid transparent; 
  border-bottom: 8px solid var(--primary-color);
  content: '';
  display: block;
  z-index: 2;
  transition: all 200ms linear;
}

.section-dropdown > a {
  position: relative;
  color: var(--boundry-color);
  transition: all 200ms linear;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 15px;
  border-radius: 2px;
  padding: 5px 0;
  padding-left: 20px;
  padding-right: 15px;
  margin: 2px 0;
  text-align: left;
  text-decoration: none;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  justify-content: space-between;
    -ms-flex-pack: distribute;
}

.section-dropdown > a:hover {
  background-color: var(--secondary-color);
}