.card-3d-wrap {
    position: relative;
    width: 500px;
    max-width: 100%;
    height: 70vh;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    perspective: 800px;
    margin-top: 60px;
}

.card-3d-wrapper {
    width: 100%;
    height: 100%;
    position:absolute;    
    top: 0;
    left: 0;  
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transition: all 600ms ease-out; 
}

.section{
    position: relative;
    width: 100%;
    display: block;
}

.checkbox:checked ~ .card-3d-wrap .card-3d-wrapper {
    transform: rotateY(180deg);
}

.full-height{
    min-height: 100vh;
  }