.checkbox:checked + label,
.checkbox:not(:checked) + label{
  position: relative;
  display: block;
  text-align: center;
  width: 60px;
  height: 16px;
  border-radius: 8px;
  border: 1px solid black;
  padding: 0;
  margin: 10px auto;
  cursor: pointer;
  background-color: var(--support-color);
}
.checkbox:checked + label:before,
.checkbox:not(:checked) + label:before{
  position: absolute;
  display: block;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  color: var(--support-color);
  background-color: var(--secondary-color);
  font-family: 'unicons-line';
  content: '\eb4f';
  z-index: 20;
  top: -10px;
  left: -10px;
  line-height: 36px;
  text-align: center;
  font-size: 24px;
  transition: all 0.5s ease;
}

.checkbox:checked + label:before {
  transform: translateX(44px) rotate(-270deg);
}

.checkbox:not(:checked) + label:before {
  color: brown;
}

.checkbox:not(:checked) + label {
  background-color: brown;
}

h6 span{
  padding: 0 20px;
  text-transform: uppercase;
  font-weight: 700;
}