.modal-button {
    text-decoration: none;
    width: 100%;
    height: 100%;
    color: #000;
    margin: 0;
    padding: 10px;
    font-family: "lexend";
}

.popup {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 200000000000000000000;
  visibility: hidden;
  opacity: 0;
  transition: 0.64s ease-in-out;
}
.popup-inner {
  position: relative;
  bottom: -100vw;
  right: -100vh;
  max-width: 800px;
  max-height: 600px;
  width: 60%;
  height: 80%;
  background-color: var(--boundry-color);
  transform: rotate(32deg);
  transition: 0.64s ease-in-out;
}
.popup-text {
  width: 100%;
  height: 100%;
  background-image: var(--background-image);
  overflow: auto;
}

.popup:target {
  visibility: visible;
  opacity: 1;
}
.popup:target .popup-inner {
  bottom: 0;
  right: 0;
  transform: rotate(0);
}
.popup-close {
  position: absolute;
  right: -1rem;
  top: -1rem;
  width: 3rem;
  height: 3rem;
  font-size: 0.875rem;
  font-weight: 300;
  border-radius: 100%;
  background-color: #0A0A0A;
  z-index: 4;
  color: var(--boundry-color);
  line-height: 3rem;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
}