.input-icon {
    position: absolute;
    top: 0;
    left: 18px;
    height: 48px;
    font-size: 24px;
    line-height: 48px;
    text-align: left;
    color: var(--primary-color);
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}

.card-back {
    transform: rotateY(180deg);
}

.form-title {
    font-weight: 600;
}

.w-49 {
    width: 49%;
}