.nav-item {
    display: flex;
    align-items: center;
    font-family: 'lexend';
    border-bottom: 1px solid var(--boundry-color);
}

.nav-item > a {
    color: rgba(255, 255, 255, 0.9);
    padding: 0.5em;
    position: relative;
    display: flex;
    align-items: center;
    flex: 1;
    text-decoration: none;
    -webkit-transition: all 0.6s ease;
    -moz-transition: all 0.6s ease;
    -ms-transition: all 0.6s ease;
    -o-transition: all 0.6s ease;
    transition: all 0.6s ease;
    font-size: 20px;

}

.nav-icon {
    width: 13%;
    font-size: 23px;
}

.nav-expand {
    margin-left: auto;
    font-size: 20px;
}
