.content {
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -ms-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    transition: all 0.5s linear;
    background-image: var(--background-image);
    background-position: center;
    background-size: cover;
    height: 100%;
    border: 1px solid var(--boundry-color);
}