.page-container {
    border: 2px solid var(--primary-color);
    overflow: hidden;
    min-height: 90.7vh;
}

.page-title {
    font-size: 40px;
    font-weight: 700;
    padding: 15px;
    color: var(--boundry-color);
    background-image: var(--background-image), linear-gradient(43deg, var(--secondary-color) 0%, var(--primary-color) 80%, var(--support-color) 200%);
}

.page-content {
    height: 100%;
}