.sidebar {
    position: fixed;
    width: 18%;
    height: 100vh;
    top: 0;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    visibility: visible;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -ms-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    transition: all 0.5s linear;
    z-index: 100;
  }
  
.sidebar header {
    background-color: var(--primary-color);
    cursor: pointer;
    color: var(--boundry-color);
    width: 100%;
    display: block;
    padding: 0.3em 1em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 40px;
    line-height: 30px;
    border-right: 1px solid var(--boundry-color);
    border-bottom: 1px solid var(--boundry-color);
    background-image: var(--background-image);
}

.sidebar-nav {
    position: fixed;
    background-color: var(--primary-color);
    background-image: var(--background-image), linear-gradient(43deg, var(--secondary-color) 0%, var(--primary-color) 80%, var(--support-color) 200%);
    height: 100%;
    font-weight: 400;
    font-size: 1.2em;
    overflow: auto;
    padding-bottom: 6em;
    z-index: 9;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    width: 100%;
}

.nav-flyout {
    position: absolute;
    background-color: var(--secondary-color);
    background-image: var(--background-image), linear-gradient(43deg, var(--secondary-color) 0%, var(--primary-color) 80%, var(--support-color) 200%);
    z-index: 9;
    left: 2.5em;
    border-left: 1px solid var(--boundry-color);
    top: 0;
    height: 100%;
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -ms-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    transition: all 0.5s linear;
}

.nav-flyin {
    -webkit-transform: translateX(-2px);
    -moz-transform: translateX(-2px);
    -ms-transform: translateX(-2px);
    -o-transform: translateX(-2px);
    transform: translateX(-2px);
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -ms-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    transition: all 0.5s linear;
}

.nav-front-button {
    display: flex;
    color: var(--boundry-color);
    transition: all 0.2s linear;
    cursor: pointer;
    border: none;
    background: transparent;
    font-size: 40px;
    padding: 0;
    margin: 0;
}

.nav-back-button {
    display: flex;
    color: var(--boundry-color);
    transition: all 0.2s linear;
    cursor: pointer;
    border: none;
    background: transparent;
    font-size: 40px;
    padding: 0;
    margin: 0;
}

.nav-front-button i {
    transition: all 0.4s ease-in;
}

.nav-back-button i {
    transition: all 0.4s ease-in;
}

.nav-front-button:hover i {
    transform: translateX(10px);
}

.nav-back-button:hover i {
    transform: translateX(-10px);
}

.page-nav {
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 10px;
    width: 150px;
    margin-left: 22%;
    padding: 0;
}

.elipsis {
    color: var(--boundry-color);
    font-size: 30px;
}