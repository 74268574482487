.nav-toggler {
    display: inline-block;
    position: absolute;
    top: 19px;
    right: -27px;
    width: 55px;
    height: 55px;
    border-top-right-radius: 10px;
    border-top: 1px groove var(--boundry-color);
    border-right: 1px groove var(--boundry-color);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    background-image: var(--background-image), 
    linear-gradient(225deg, var(--primary-color) 52%, transparent 50%);
}

.nav-toggler > button {
    background: transparent;
    border: none;
    color: var(--boundry-color);
    margin-left: 1em;
    margin-top: 0em;
    font-size: 20px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.nav-toggler > button:hover > i {
    font-size: 16px;
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -ms-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    transition: all 0.5s linear;
}


.nav-collapse {
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
}

.content-collapse {
    width: 81.9%;
    -webkit-transform: translateX(22%);
    -moz-transform: translateX(22%);
    -ms-transform: translateX(22%);
    -o-transform: translateX(22%);
    transform: translateX(22%);
}