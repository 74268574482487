.navbar-animmenu {
    background: var(--primary-color);
	overflow: hidden;
	position: relative;
	padding: 10px 0px;
    width: 100%;
	border-bottom: 1px solid var(--boundry-color);
	padding-left: 25px;
}
.navbar-animmenu ul {
	padding: 0px;
	margin: 0px;  
}

.navbar-animmenu ul li a i {
	margin-right: 10px;
}

.navbar-animmenu li {
	list-style-type: none;
	float: left;
}

.navbar-animmenu ul li a {
	color: var(--boundry-color);
    text-decoration: none;
    font-size: 20px;
    line-height: 45px;
    display: block;
    padding: 0px 20px;
    transition-duration:0.6s;
	transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    position: relative;
}

.navbar-animmenu ul li.active a {
	color: #000;
}

.navbar-animmenu a:not(:only-child):after {
	content: "\f105";
	position: absolute;
	right: 20px;
	top: 10%;
	font-size: 20px;
	font-family: "Font Awesome 5 Free";
	display: inline-block;
	padding-right: 3px;
	vertical-align: middle;
	font-weight: 900;
	transition: 0.5s;
}

.hori-selector{
	display:inline-block;
	position:absolute;
	height: 100%;
	transition-duration:0.6s;
	transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
	background-color: var(--boundry-color);
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
}
.hori-selector .right,
.hori-selector .left {
	position: absolute;
	width: 25px;
	height: 25px;
	background-color: var(--boundry-color);
	bottom: 10px;
}
.hori-selector .right {
	right: -25px;
}
.hori-selector .left {
	left: -25px;
}
.hori-selector .right:before,
.hori-selector .left:before {
	content: '';
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--primary-color);
}
.hori-selector .right:before {
	bottom: 0;
    right: -25px;
}
.hori-selector .left:before{
	bottom: 0;
    left: -25px;
}