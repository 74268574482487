.section{
    position: relative;
    width: 100%;
    display: block;
    overflow: hidden;
}

.center-wrap{
    position: absolute;
    width: 100%;
    padding: 0 35px;
    top: 50%;
    left: 0;
    transform: translate3d(0, -50%, 35px) perspective(100px);
    z-index: 20;
    display: block;
}

.form-card {
    width: 100%;
    height: 100%;
    background-color: var(--primary-color);
    background-image: var(--background-image), linear-gradient(43deg, var(--secondary-color) 0%, var(--primary-color) 70%, var(--support-color) 100%);
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: 300%;
    border-top-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
    position: absolute;
    box-shadow: 0px 0px 24px 3px rgba(0,0,0,0.68);
    -webkit-box-shadow: 0px 0px 24px 3px rgba(0,0,0,0.68);
    -moz-box-shadow: 0px 0px 24px 3px rgba(0,0,0,0.68);
    /* border-radius: 6px; */
    left: 0;
    top: 0;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
  }