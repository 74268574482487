.section-container {
    margin: 0;
    border-bottom: 1px solid var(--primary-color);
    overflow: hidden;
}

.section-title {
    font-size: 35px;
    padding: 10px 20px;
    color: var(--boundry-color);
    background-image: var(--background-image), linear-gradient(43deg, var(--secondary-color) 0%, var(--primary-color) 80%, var(--support-color) 200%);
}

.section-content {
    padding: 0;
}