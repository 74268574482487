.loader-container {
    width: 90vw;
    height: 90vh;
    z-index: 10000000000000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    border: 16px solid var(--secondary-color);
    border-top: 16px solid var(--primary-color);
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}