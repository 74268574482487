:root {
    --primary-color: #063F30;
    --secondary-color: #070020;
    --support-color: #BFE85F;
    --boundry-color: #fff;
    --background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/1462889/pat.svg");
}

.w-110px {
    width: 110px;
}

.h-100vh {
    height: 100vh
}

.delete-attribute-button {
    color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    border-radius: 10px;
    background-color: var(--boundry-color);
    box-shadow: 0px 0px 14px -4px rgba(0,0,0,0.55);
    -webkit-box-shadow: 0px 0px 14px -4px rgba(0,0,0,0.55);
    -moz-box-shadow: 0px 0px 14px -4px rgba(0,0,0,0.55);
}

.delete-attribute-button:hover {
    background-color: red;
    color: var(--boundry-color);
}

.opacity-0 {
    opacity: 0;
}

.content-header {
    display: flex;
    align-items: center;
    color: var(--boundry-color);
    height: 100px;
    padding: 0 1.5rem;
    margin-top: 6rem;
    background-image: var(--background-image), linear-gradient(43deg, var(--secondary-color) 0%, var(--primary-color) 80%, var(--support-color) 400%);
}

.cards-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
}

.cards-list .card-item {
    width: 95%;
    overflow: hidden;
    background-color: rgb(244, 244, 244);
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
    border-top: 1px dashed var(--primary-color);
    border-left: 1px dashed var(--primary-color);
    background-image: var(--background-image);
    box-shadow: 10px 10px 14px 4px var(--secondary-color);
    -webkit-box-shadow: 10px 10px 14px 4px var(--secondary-color);
    -moz-box-shadow: 10px 10px 14px 4px var(--secondary-color);
}

.cards-list .card-item .card-item-head {
    width: 100%;
    height: 40px;
    color: var(--boundry-color);
    font-size: 25px;
    padding: 30px;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    display: flex;
    align-items: center;
    background-image: var(--background-image), linear-gradient(43deg, var(--secondary-color) 0%, var(--primary-color) 80%, var(--support-color) 400%);
}

.cards-list .card-item .card-item-head i {
    margin: 6px;
}

.cards-list .card-item .card-item-body {
    width: 100%;
    height: 70px;
    color: var(--primary-color);
    display: flex;
    align-items: center;
    font-size: 20px;
    padding: 25px;
}

.cards-list .card-item .card-item-body .role-assign-checkbox {
    width: 20px;
    height: 20px;
    border: 1px solid var(--primary-color);
    margin: 6px;
}

.cards-list .card-item .card-item-body div,  .cards-list .card-item .card-item-head div {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cards-list .card-item .card-item-body div {
    width: 49%;
}

.popup-header {
    font-size: 3.7rem;
    font-weight: 600;
    margin-bottom: 2rem;
    color: var(--boundry-color);
    font-family: "lexend";
    padding: 3rem;
    background-image: var(--background-image), linear-gradient(43deg, var(--secondary-color) 0%, var(--primary-color) 80%, var(--support-color) 400%);
}

.popup-content {
    display: flex;
    justify-content: center;
    font-size: 2rem;
    color: var(--primary-color);
    line-height: 1.5;
    margin: 7rem 2rem 0 2rem;
}

.popup-button-wrapper {
    display: flex;
    justify-content: center;
}

.delete-button {
    width: 220px!important;
    height: 80px!important;
    font-size: 2rem!important;
    margin-top: 5rem!important;
    background-image: var(--background-image), linear-gradient(43deg, #200000 0%, #810f0f 80%, var(--support-color) 400%)!important;
}

.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-container button {
    width: 465px;
    height: 100px;
    font-size: 30px;
}

.expand-card-item {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: visible!important;
    width: 230px;
    height: 50px;
    color: var(--boundry-color);
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    background-color: var(--secondary-color);
    transition: all 0.5s ease;
}

.expand-card-item:hover {
    cursor: pointer;
    width: 200px;
}

.expand-card-item i {
    font-size: 25px;
    margin: 5px 0;
}

.expand-card-item-list {
    max-height: 10000px!important;
    transition: all 0.5s ease!important;
}

.card-item-subitems {
    max-height: 0;
    color: #000;
    margin: 0;
    padding: 0;
    width: 100%;
    transition: all 0.7s ease;
}

.card-item-head-not-checked {
    color: white!important;
    background-image: none!important;
    background-image: var(--background-image), linear-gradient(43deg, #200000 0%, #810f0f 80%, var(--support-color) 400%)!important;
}

.edit-user-container {
    margin: 1rem;
    background-image: var(--background-image);
}

.edit-user-header {
    display: flex;
    align-items: center;
    color: var(--boundry-color);
    height: 100px;
    padding: 0 1.5rem;
    background-image: var(--background-image), linear-gradient(43deg, var(--secondary-color) 0%, var(--primary-color) 80%, var(--support-color) 400%);
}

.edit-user-header button {
    padding: 5px 20px;
    height: 60px;
    border-radius: 10px;
    border: 2px solid #eee;
    background-image: none;
    background-color: var(--secondary-color);
}

.user-info {
    margin-bottom: 4rem;
}

.user-roles {
    margin-bottom: 4rem;
}

.info-input {
    padding: 0;
    width: 400px;
    height: 50px;
}

.info-input input {
    margin: 5px 0;
    padding: 2px 15px;
    width: 98%;
    height: inherit;
    border-radius: 8px;
    border: none;
    font-size: 20px;
    color: var(--primary-color);
    border: 1px dashed var(--primary-color);
    background-color: rgb(235, 235, 235);
}

.user-avatar-password {
    display: flex;
    justify-content: space-between;
    height: 600px;
}

.user-generate-password {
    width: 50%;
    height: 100%;
}

.user-generate-password .password-hint {
    margin: 25px;
    color: var(--primary-color);
    font-size: 25px;
}

.user-generate-password .password-button {
    font-size: 20px;
    margin: 25px 25px 50px 25px;
}

td {
    font-size: 25px;
}

.no-data {
    width: 99.7%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 45px;
    margin: 5rem 0.3rem;
    color: brown;
}

.enable-disable-user {
    margin: 3rem 1rem;

}

.enable-disable-switch {
    margin: 2rem 0;
    width: 34%;
}

.enable-disable-hint {
    font-size: 25px;
    margin: 1rem;
}

.input-icon {
    position: absolute;
    top: 0;
    left: 18px;
    height: 48px;
    font-size: 24px;
    line-height: 48px;
    text-align: left;
    color: var(--primary-color);
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}

.modal-input {
    color: var(--primary-color);
    font-size: 20px;
    margin: 3rem 1rem;
    padding: 1rem;
    height: 80px;
    width: 80%;
    border-radius: 30px;
    background-color: rgb(224, 224, 224);
    border: 2px dashed var(--primary-color);
    text-align: center;
}

.modal-input[type="text"]:focus {
    border: 2px dashed var(--primary-color);
    outline: none;
}

.message-recipients-question {
    font-size: 30px;
    margin: 30px 10px;
}

.date-button-icon {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.date-button-input {
    position: absolute;
    border: none;
    background-color: transparent;
    outline: none;
    width: 35px;
    transform: scale(1.3);
    color: transparent;
}

.date-button-input::-webkit-calendar-picker-indicator {
    filter: invert(1);
    opacity: 0;
    cursor: pointer;
    width: 35px;
}

.date-button-input::placeholder {
    color: transparent;
}

.date-button-input:focus::placeholder,
.date-button-input:not(:placeholder-shown) {
    width: 35px;
}