.notification-container {
  position: fixed;
  left: 20.5%;
  top: 10px;
  text-align: center;
  border: 1px solid var(--boundry-color);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
  transition: 
    width 0.5s ease-in-out, 
    height 0.5s ease-in-out, 
    transform 0.5s ease-in-out,
    border-radius 0.5s ease-in-out;
  z-index: 9999;
  background-image: var(--background-image), linear-gradient(43deg, var(--secondary-color) 0%, var(--primary-color) 80%, var(--support-color) 200%);
}

.notification-icon {
  width: 70px;
  height: 70px;
  cursor: pointer;
  color: var(--boundry-color);
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  font-size: 40px;
}

.notification-count {
  position: absolute;
  color: var(--boundry-color);
  font-size: 20px;
}

.notification-expand {
  width: 550px;
  height: 450px;
  border-radius: 10px;
  cursor: auto;
}

.notification-expand .notification-icon {
  border-top: 1px solid var(--boundry-color);
  border-left: 1px solid var(--boundry-color);
  border-top-left-radius: 1000%;
  color: var(--boundry-color);
  background-color: #000;
  padding-left: 10px;
  padding-top: 10px;
  cursor: pointer;
}

.notification-content {
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
}

.notifications-list {
  overflow: auto;
  height: 380px;
  color:var(--boundry-color)
}

.notification-item {
  border: 1px dashed var(--boundry-color);
  margin: 20px;
  border-radius: 20px;
  overflow: hidden;
}

.notification-subject {
  background-color: var(--secondary-color);
  border-bottom: 1px solid var(--boundry-color);
  padding: 10px;
}

.notification-subject {
  background-color: var(--secondary-color);
  border-bottom: 1px solid var(--boundry-color);
  padding: 10px;
  text-align: center;
  font-size: 25px;
}

.notification-content {
  padding: 10px;
  text-align: start;
}

.notification-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: red;
  height: 90%;
}

.notification-not-empty {
  color: red;
}