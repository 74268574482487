
.fl-table {
    border-radius: 5px;
    font-size: 12px;
    font-weight: normal;
    width: 100%;
    background-color: var(--boundry-color);
    margin: 10px 0;
}

.fl-table td, .fl-table th {
    text-align: center;
    padding: 8px;
}

.fl-table td {
    border-right: 1px solid #e7e6e6;
    word-wrap: break-word;
    font-size: 16px;
}

.fl-table .td-break {
    word-break: break-all;
}

.fl-table .th-nowrap {
    white-space: nowrap;
}

.fl-table thead th {
    color: var(--boundry-color);
    background: var(--primary-color);
    font-size: 20px;
}


.fl-table thead th:nth-child(odd) {
    color: var(--boundry-color);
    background: var(--secondary-color);
}

.fl-table tr:nth-child(even) {
    background: #eee;
}

.fl-table tr:nth-child(even) td {
    border-right: 1px solid var(--boundry-color);
}

.fl-table .operation {
    width: 10px;
}

.fl-table .operation:hover {
    color: var(--support-color);
    cursor: pointer;
    background-color: var(--primary-color);
}

.fl-table .page-head {
    border: none;
}

.fl-table .last-row {
    border-top: 1px solid var(--primary-color);
    padding: 0;
    margin: 0;
}

.fl-table .pagination-row {
    background-color: transparent!important;
}

.fl-table .page-navigator {
    border: none;
}

.fl-table .per-page {
    border: transparent;
}

.fl-table .page-input {
    width: 0!important;
    border: transparent;
    color: var(--primary-color);
}

.fl-table .pagination-info {
    display: flex;
    justify-content: space-evenly;
    width: 60%;
    margin: -10px auto 15px auto;
}

.fl-table .btn-del {
    color: rgb(177, 5, 5);
}

.fl-table .btn-del:hover {
    color: var(--boundry-color);
    background-color: red;
}

.add-button {
    margin: 0;
    height: 100%;
    color: var(--boundry-color);
    text-transform: uppercase;
    border-radius: 0;
    font-size: 20px;
    padding: 10px 15px;
    text-decoration: none;
    border: 3px solid var(--boundry-color);
}

.add-button i {
    margin: 0 5px;
}