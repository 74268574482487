.file-icon {
    position: absolute;
    margin-left: -37px;
    margin-top: -13px;
    height: 48px;
    font-size: 24px;
    line-height: 48px;
    text-align: left;
    color: var(--primary-color);
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}

.card-back {
    transform: rotateY(180deg);
}

.link {
    color: #c4c3ca;
}

.link:hover {
    color: #ffeba7;
}

.form-title {
    font-weight: 600;
}