.home-content {
    background-image: var(--background-image);
    z-index: -10!important;
}

.home-content video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1000;
}
  