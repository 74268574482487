.back-button {
    display: flex;
    height: 3em;
    width: 100px;
    align-items: center;
    justify-content: center;
    color: var(--primary-color);
    border-radius: 3px;
    letter-spacing: 1px;
    transition: all 0.2s linear;
    cursor: pointer;
    border: none;
    background: var(--boundry-color);
    font-weight: 600;
}
   
.back-button > svg {
    margin-right: 5px;
    margin-left: 5px;
    transition: all 0.4s ease-in;
}
   
.back-button:hover > svg {
    transform: translateX(-10px);
    fill: var(--boundry-color);
}
   
.back-button:hover {
    box-shadow: 9px 9px 33px #d1d1d1, -9px -9px 33px var(--primary-color);
    transform: translateY(-2px);
    color: var(--boundry-color);
    background-image: var(--background-image), linear-gradient(43deg, var(--secondary-color) 0%, var(--primary-color) 60%, var(--support-color) 120%);
}