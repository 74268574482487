.image-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
}

.image-container .avatar-hint {
    color: var(--primary-color);
    font-size: 30px;
    margin: 20px 0;
}

.image-container .image-wrapper {
    position: relative;
    display: inline-block;
    width: 400px;
    height: 400px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid var(--primary-color);
    margin: 0;
}
  
.image-container img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
.image-container input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}
  
.image-container .image-wrapper i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    color: var(--primary-color);
    font-size: 50px;
}
  
.image-container .image-wrapper:hover i {
    display: block;
    cursor: pointer;
}
  
.image-container .image-wrapper:hover {
    opacity: 0.8;
}
  
.image-container .image-wrapper:hover img {
    opacity: 0.5;
}